import { actualSite, lojaInterface } from "@/app/utils/utils";
import Image from "next/image";
import React, { useState } from "react";
import ContactInfo from "./ContactInfo";
import OperatingHours from "./OperatingHours";

export const Contact: React.FC = () => {
  const { lojas } = actualSite();
  const [loja, setLoja] = useState<lojaInterface | undefined>(lojas[0]);
  return (
    <div className="flex flex-col justify-center">
      <section 
        className="flex relative flex-col w-full min-h-[382px] max-md:max-w-full"
        style={{
          backgroundImage: 'url("/images/fundo_sessao_endereco.png")',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      >
        <div className="flex max-md:pb-80 relative z-10 p-8 flex-row justify-center items-end w-full max-md:pl-5 max-md:max-w-full">
          <Image
            width={467}
            height={301}
            loading="lazy"
            src="/images/loja_toyota.png"
            alt=""
            className="absolute bottom-0 left-0 w-[467px] h-[301px]"
          />
          <div className="max-w-[1140px] w-full mx-auto px-4">
            <div className="max-w-full ml-auto backdrop-blur-sm rounded-lg bg-black bg-opacity-30 w-[712px]">
              <div className="flex gap-5 max-md:flex-col">
                <ContactInfo setLoja={setLoja} loja={loja} />
                <OperatingHours loja={loja} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
